<template>
  <div id="header">
    <div class="header-nav">
      <ul>
        <li><router-link to="/">{{lang.home}}</router-link></li>
        <li><router-link to="/dashboard/posts">{{lang.dashboard}}</router-link></li>
        <li style="position: absolute; top: 0; right: 0;cursor: pointer">
          <el-dropdown trigger="click" @command="handleCurrentLanguage" style="color:white;border: 1px thin;border-radius: 4px; padding: 0 .2rem">
            <span class="el-dropdown-link">
              <span style="padding: 5px">{{currentLanguage}}</span><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">EN</el-dropdown-item>
              <el-dropdown-item command="vi">VI</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import STORE_KEY from '../../const/storeKey'
const { ACTIONS, _LANGUAGES } = STORE_KEY
export default {
  data() {
    return {
      defaultLayout: "/",
      isShowSearch: false,
      currentLanguage: localStorage.getItem('langs') && localStorage.getItem('langs').toUpperCase() == 'EN' ? 'EN' : 'VI' 
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      if (location.pathname !== key) this.$router.push(key);
    },
    handleCurrentLanguage(language){
      this.storeVue(_LANGUAGES).dispatch(ACTIONS._LANGUAGES.CHANGE_LANGUAGES, language)
      this.currentLanguage = language.toUpperCase()
      localStorage.setItem('langs', language)
    }
  },
  computed: {
    lang(){ return this.langs() }
  }
};
</script>
<style lang="scss" scoped>
#header {
  // position: fixed;
  // top: 0;
  // z-index: 999;
  // width: 100%;
  .header-nav {
    ul {
      background-color: #222d3f;
      padding:1%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(34,45,63,1)), color-stop(64%,rgba(42,71,102,1)), color-stop(64%,rgba(42,71,102,1)), color-stop(100%,rgba(78,100,123,1))); /* Chrome,Safari4+ */
      li {
        display: inline;
        padding:1%;
        color:white;
        font-family: Helvetica, sans-serif;
        a {
          text-decoration: none;
          color: #fff;
          -webkit-transition: all 1s;
          &:hover {
            color:#bee0e7;
            border-radius:3px;
            border:5px ilset blue;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
:root {
  --li-hover: #00acac;
}

header {
  background: #d6d6d6;
  color: #000000;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  top: 0;
  padding: 0.4375rem 0 !important;
  transition: all 0.2s linear;
  .header {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 10px;

    .navbar-brand {
      > svg {
        align-self: center;
        fill: rgb(0, 0, 0);
      }
      :hover {
        fill: var(--li-hover) !important;
      }
    }
  }
}

// .navbar-brand:hover svg {
//   fill: var(--li-hover) !important;
// }

.logoOnHover {
  display: none;
}
.navbar-brand:hover .logoOnHover {
  display: unset;
}

/* header search */
.header form input {
  width: 0%;
  border: 0;
  border-bottom: none;
  transition: width 0.4s;
  color: var(--li-hover);
  background: transparent;
  outline: none;
}

.header form {
  align-self: center;
  flex: 1 0 0;
  display: flex;
  padding-left: 10px;
}

.header form:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 25px;
  background: #a6a6a6;
  transform: translate(0px, 25%);
}

.header form > div {
  padding-left: 10px;
}
.header form .btn {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;

  background: #eee;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.navbar-toggle {
  display: none;
}

.navbar-collapse {
  align-self: center;
  a {
    padding: 0 5px;
  }
}
</style>