<template>
  <div id="post-detail">
    <Header></Header>
    <el-main>
      <div class="box-post">
        <el-breadcrumb separator="/">
  <el-breadcrumb-item :to="{ path: '/' }">Trang Chủ</el-breadcrumb-item>
  <el-breadcrumb-item>{{getCategory(post.categoryId)}}</el-breadcrumb-item>
</el-breadcrumb>
        <h1 class="title">{{post.title}}</h1>
         <el-image v-if="post.image" :src="post.image" :fit="'cover'" style="display: block"></el-image>
        <div v-html="post.content" class="content"></div>
      </div>
    </el-main>
  </div>
</template>

<script>
import ENUM from "../../../../const/enum";
import Header from "../../../../components/Header";
import store from "./_store";

const { KEY_NAME, ACTION } = store;
export default {
    data() {
        return {
          post: {},
        }
      },
  components: {
    Header,
  },
  created() {
    let { _id } = this.$route.params
    let index = this.cacheContent.findIndex(i=> i._id == _id) 
    if( index > -1) return this.post = this.cacheContent[index]  
    else this.postJSON(ENUM.POSTS.GET_CONTENT_BY_ID, { _id }, r => {
      const { ok , data } = r 
      if(!ok || !Object.entries(data).length) return this.$router.push('/');
      this.post = r.data
      this.CHANGE_CACHE_CONTENT(r.data)
    });

//  get category
     if(!this.categories.length){
      this.postJSON(ENUM.POSTS.GET_CATEGORY, {}, r => {
        const {ok, data} = r
        if(!ok) return this.message('Error', 'Không lấy được danh sách Chủ đề từ server')
        this.CHANGE_CATEGORIES(data)
      })
    }
  },
  mounted() {},
  watch: {
    post() {
      setTimeout(() => Prism.highlightAll(), 0);
    }
  },
  methods:{
    CHANGE_CACHE_CONTENT(data){
      this.storeVue(KEY_NAME).dispatch('CHANGE_CACHE_CONTENT', data)
    },
    CHANGE_CATEGORIES(data){
      this.storeVue('_POSTS').dispatch('CHANGE_CATEGORIES', data)
    },
    getCategory(_id){
      let category = this.categories.find(i=> i._id == _id)
      if(!category) return '...'
      return category.name
    }
  },
  computed: {
    // currentIdPost(){
    //   return this.storeVue(KEY_NAME).getters.currentIdPost
    // },
    cacheContent(){
      return this.storeVue(KEY_NAME).getters.cacheContent
    },
    categories(){
      return this.storeVue('_POSTS').getters.categories
    }
  },
};
</script>

<style lang="scss" scoped>
#post-detail {
  .box-post{
    box-shadow: 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f, 0 8px 10px -5px #00000033;
    padding: 8px 15px;
    border-radius: 6px;
    width: 100%; 
    max-width: 800px;
    .title {
      text-align: center;
      font-size: 1.4rem;
    }
  }
}

</style>