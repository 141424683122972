require('dotenv').config()
const URL = process.env.VUE_APP_URL_SERVER
export default {
    MEDIA : process.env.VUE_APP_URL_MEDIA,
    POSTS: {
        GET_CATEGORY: URL + 'posts/getCategory',
        CREATE_CATEGORY: URL + 'posts/createCategory',
        CREATE_POST: URL + 'posts/createPost',
        GET_POSTS: URL + 'posts/getPosts',
        GET_CONTENT_BY_ID: URL + 'posts/getContentById',
        SEARCH: URL + 'posts/search',
        EDIT_POST: URL + 'posts/editPost'
    },
    CATEGORIES:{
        
    }
}